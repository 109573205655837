* {
  box-sizing: border-box;
  font-family: 'Open Sans';
}

.header {
  text-align: center;
  padding: 200px 0px;
  text-transform: uppercase;
  font-family: 'Montserrat';
  -webkit-animation: rainbow 30s ease infinite;
  animation: rainbow 30s ease infinite;
  background: radial-gradient(circle at 100%, black, transparent),
    radial-gradient(circle at 0%, black, transparent),
    linear-gradient(0deg, black, transparent);
  color: white;
}

body {
  height: 100vh;
  margin: 0;
  background-color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.header h1,
.header h2 {
  letter-spacing: 10px;
  font-family: 'Montserrat';
}
.header h1 {
  font-size: 48px;
}
@-webkit-keyframes rainbow {
  0% {
    background-color: purple;
  }
  10% {
    background-color: magenta;
  }
  20% {
    background-color: blue;
  }
  30% {
    background-color: cyan;
  }
  40% {
    background-color: teal;
  }
  50% {
    background-color: green;
  }
  60% {
    background-color: lime;
  }
  70% {
    background-color: yellow;
  }
  80% {
    background-color: orange;
  }
  90% {
    background-color: red;
  }
  100% {
    background-color: purple;
  }
}
@keyframes rainbow {
  0% {
    background-color: purple;
  }
  10% {
    background-color: magenta;
  }
  20% {
    background-color: blue;
  }
  30% {
    background-color: cyan;
  }
  40% {
    background-color: teal;
  }
  50% {
    background-color: green;
  }
  60% {
    background-color: lime;
  }
  70% {
    background-color: yellow;
  }
  80% {
    background-color: orange;
  }
  90% {
    background-color: red;
  }
  100% {
    background-color: purple;
  }
}
